import axios, { CancelToken, CancelTokenSource } from "axios";
import ScheduleMeetingRequest from "./requests/ScheduleMeetingRequest";
import JoinMeetingByJoinUrlRequest from "./requests/JoinMeetingByJoinUrlRequest";
import StartMeetingNowRequest from "./requests/StartMeetingNowRequest";
import { EventEmitter } from "events";
import { User } from "./payloads/User";
import Event from "./payloads/Event";
import EventsConfig from "./payloads/EventsConfig";
import PaginatedList from "./payloads/PaginatedList";
import { EventType } from "./payloads/EventType";
import { EventSessionStatus } from "./payloads/EventSessionStatus";
import CommandConfig from "./payloads/CommandConfig";
import Command from "./payloads/Command";
import { IStage } from "./payloads/Stage";
import Token from "./payloads/Token";
import AzureCommunicationToken from "./payloads/AzureCommunicationToken";
import CreateMeetingByMsIdRequest from "./requests/CreateMeetingByMsIdRequest";
import UpdateEventByMsIdRequest from "./requests/UpdateEventByMsIdRequest";
import JoinMeetingByTeamsIdRequest from "./requests/JoinMeetingByTeamsIdRequest";
import ParticipantsCollection from "./payloads/ParticipantsCollection";
import CustomrMessage from "./payloads/Message";
import GetIsOwnerByTeamsIdResponse from "./payloads/GetIsOwnerByTeamsIdResponse";
import { IEventAdditionalInfo } from "common/props/store/IPreMeetingStore";
import CommunicationOptions from "./payloads/CommunicationOptions";
import Logo from "./payloads/Logo";
import PrepareUploadRequest from "./requests/PrepareUploadRequest";
import PrepareUploadResponse from "./payloads/PrepareUploadResponse";
import BaseApiClient from "./BaseApiClient";
import CommandV2 from "./payloads/CommandV2";
import AzureApplicationConfiguration from "./payloads/AzureApplicationConfiguration";
import GeneralConfiguration from "./payloads/GeneralConfiguration";
import { ISurvey } from "./payloads/Survey";
import SaveSurveyRequest from "./requests/SaveSurveyRequest";
import SurveyRequest from "./requests/SurveyRequest";
import { IQuestions } from "slices/props/IMeetingStore";
import Language from "./payloads/Language";

export interface ICancellableClient {
  createCancelTokenSource(): CancelTokenSource;
}

export interface IUtilitiesClient {
  getCommunicationOptions(
    cancelToken?: CancelToken
  ): Promise<CommunicationOptions>;
  getGeneralConfiguration(
    cancelToken?: CancelToken
  ): Promise<GeneralConfiguration>;
}
export interface IAzureApplicationClient {
  getAzureAppInfo(): Promise<AzureApplicationConfiguration>;
}
export interface IMeetingClient {
  scheduleMeeting(
    request: ScheduleMeetingRequest,
    cancelToken?: CancelToken
  ): Promise<Event>;
  scheduleEvent(
    request: ScheduleMeetingRequest,
    cancelToken?: CancelToken
  ): Promise<Event>;
  startMeetingNow(
    request: StartMeetingNowRequest,
    cancelToken?: CancelToken
  ): Promise<Event>;
  startEventNow(
    request: StartMeetingNowRequest,
    cancelToken?: CancelToken
  ): Promise<Event>;
  joinMeetingByJoinUrl(
    request: JoinMeetingByJoinUrlRequest,
    cancelToken?: CancelToken
  ): Promise<Event>;
  joinMeetingByMeetingMsId(
    request: JoinMeetingByTeamsIdRequest,
    cancelToken?: CancelToken
  ): Promise<Event>;

  getMeetingConfig(cancelToken?: CancelToken): Promise<EventsConfig>;
  getEvent(
    eventId: string,
    sessionId?: string,
    cancelToken?: CancelToken
  ): Promise<Event>;
  getEventWithRecordings(
    eventId: string,
    sessionId?: string,
    cancelToken?: CancelToken
  ): Promise<Event>;
  updateMeeting(
    meeting: Partial<Event>,
    cancelToken?: CancelToken
  ): Promise<Event>;
  updateSpeakersAdditionalInfo(
    eventId: string,
    additionalInfo: IEventAdditionalInfo,
    cancelToken?: CancelToken
  ): Promise<void>;
  updateListenersAdditionalInfo(
    eventId: string,
    additionalInfo: IEventAdditionalInfo,
    cancelToken?: CancelToken
  ): Promise<void>;
  getParticipants(
    eventId: string,
    cancelToken?: CancelToken
  ): Promise<ParticipantsCollection>;
  setActiveSpeaker(
    eventId: string,
    speakerId: string | null,
    cancelToken?: CancelToken
  ): Promise<void>;
  setSpeechRecognitionState(
    eventId: string,
    isEnabled: boolean,
    cancelToken?: CancelToken
  ): Promise<void>;
  setFormFulfillmentState(
    eventId: string,
    isEnabled: boolean,
    cancelToken?: CancelToken
  ): Promise<void>;
  setSegmentationState(
    eventId: string,
    isEnabled: boolean,
    cancelToken?: CancelToken
  ): Promise<void>;
  setGazeRedirectionState(
    eventId: string,
    isEnabled: boolean,
    cancelToken?: CancelToken
  ): Promise<void>;
  setListenersAudioState(
    eventId: string,
    isMuted: boolean,
    cancelToken?: CancelToken
  ): Promise<void>;
  setTargetLanguage(
    eventId: string,
    isListenersCall: boolean,
    targetLanguage: string | null,
    cancelToken?: CancelToken
  ): Promise<void>;
  getSupportedLanguages(
    eventId: string,
    cancelToken?: CancelToken
  ): Promise<Language[]>;
  endMeeting(eventId: string, cancelToken?: CancelToken): Promise<void>;
  getMeetingByMsId(msId: string, cancelToken?: CancelToken): Promise<Event>;
  createMeetingByMsId(
    request: CreateMeetingByMsIdRequest,
    cancelToken?: CancelToken
  ): Promise<Event>;
  updateEventByMsId(
    msId: string,
    request: UpdateEventByMsIdRequest,
    cancelToken?: CancelToken
  ): Promise<Event>;
  getIsOwnerByTeamsId(
    teamsId: string,
    cancelToken?: CancelToken
  ): Promise<GetIsOwnerByTeamsIdResponse>;
  getIsOwner(
    eventId: string,
    cancelToken?: CancelToken
  ): Promise<GetIsOwnerByTeamsIdResponse>;
  setLogo(
    eventId: string,
    logoId?: string,
    cancelToken?: CancelToken
  ): Promise<Event>;
  attachSlates(
    eventId: string,
    slatesIds?: Array<string>,
    cancelToken?: CancelToken
  ): Promise<Event>;
  getQuestionsAnalytics(eventId: string): Promise<IQuestions>;
  getQuestionsCount(eventId: string): Promise<number>;
  downloadCsvFile(eventId: string, cancelToken?: CancelToken): Promise<File>;
}

export interface ISendMessage {
  sendMessage(
    message: CustomrMessage,
    cancelToken?: CancelToken
  ): Promise<string>;
}
export interface IOrganizationClient {
  getUsersCount(cancelToken?: CancelToken): Promise<number>;
  getUsers(
    offset: number,
    count: number,
    cancelToken?: CancelToken
  ): Promise<Array<User>>;
  searchUsers(
    searchString: string,
    cancelToken?: CancelToken
  ): Promise<Array<User>>;
}

export interface IAuthenticationClient {
  setAccessToken(token: string | null): void;
}

export interface ICommandClient {
  getCommandConfig(cancelToken?: CancelToken): Promise<CommandConfig>;
  sendCommand(command: Command, cancelToken?: CancelToken): Promise<string>;
  sendCommandV2(command: CommandV2, cancelToken?: CancelToken): Promise<string>;
}
export interface IAzureCommunicationServicesClient {
  generateAzureCommunicationServiceAccessToken(): Promise<AzureCommunicationToken>;
  refreshAzureCommunicationServiceAccessToken(
    identity: string
  ): Promise<AzureCommunicationToken>;
}
export interface IUserClient {
  getUserInfo(cancelToken?: CancelToken): Promise<User>;
  getUserInfoTeams(
    teamsAccessToken: string,
    cancelToken?: CancelToken
  ): Promise<User>;
  getUserMeetings(
    offset: number,
    count: number,
    type: EventType | null,
    status: EventSessionStatus | null,
    cancelToken?: CancelToken
  ): Promise<PaginatedList<Event>>;
  getLogos(cancelToken?: CancelToken): Promise<Logo[]>;
  createLogo(uploadId: string, cancelToken?: CancelToken): Promise<Logo>;
  removeLogo(logoId: string, cancelToken?: CancelToken): Promise<Logo>;
}

export interface IStageClient {
  getStagesList(): Promise<Array<IStage>>;
  getVenue(venueId: string, cancelToken?: CancelToken): Promise<IStage>;
  getRecommendedStages(cancelToken?: CancelToken): Promise<IStage[]>;
}

export interface IPlayerClient {
  getOutputUrl(eventId: string, cancelToken?: CancelToken): Promise<Event>;
}

export interface IUploadsClient {
  prepareUpload(
    request: PrepareUploadRequest,
    cancelToken?: CancelToken
  ): Promise<PrepareUploadResponse>;
}

export interface ISurveyClient {
  getSurveys(
    organizationId: string,
    cancelToken?: CancelToken
  ): Promise<ISurvey[]>;
  getSurveyById(
    request: SurveyRequest,
    cancelToken?: CancelToken
  ): Promise<ISurvey>;
  saveSurveyResponse(
    saveSurveyRequest: SaveSurveyRequest,
    cancelToken?: CancelToken
  ): Promise<boolean>;
}

export enum EventTypes {
  InvalidToken = "InvalidToken",
  InvalidSubscription = "InvalidSubscription",
}

class ApiClient
  extends BaseApiClient
  implements
    IMeetingClient,
    IAuthenticationClient,
    IUserClient,
    ICommandClient,
    ICancellableClient,
    IStageClient,
    IPlayerClient,
    ISendMessage,
    IUtilitiesClient,
    IAzureCommunicationServicesClient,
    IUploadsClient,
    ISurveyClient
{
  constructor() {
    super(window.location.origin);
  }

  async getAzureAppInfo(): Promise<AzureApplicationConfiguration> {
    const result = await this.get<AzureApplicationConfiguration>(
      "api/azure/app"
    );
    return result;
  }

  async sendMessage(
    mssage: CustomrMessage,
    cancelToken?: CancelToken
  ): Promise<string> {
    const result = await this.post<string>(
      "/api/messages/send",
      mssage,
      cancelToken
    );
    return result;
  }

  async getStagesList(): Promise<IStage[]> {
    const result = await this.get<IStage[]>("/api/venues");
    return result;
  }

  async getCommunicationOptions(
    cancelToken?: CancelToken
  ): Promise<CommunicationOptions> {
    return await this.get<CommunicationOptions>(
      "api/communications/options",
      cancelToken
    );
  }

  async getGeneralConfiguration(
    cancelToken?: CancelToken
  ): Promise<GeneralConfiguration> {
    return await this.get<GeneralConfiguration>(
      "api/configuration/general",
      cancelToken
    );
  }

  async getVenue(venueId: string, cancelToken?: CancelToken): Promise<IStage> {
    const result = await this.get<IStage>(
      `/api/venues/${venueId}`,
      cancelToken
    );
    return result;
  }
  async getRecommendedStages(cancelToken?: CancelToken): Promise<IStage[]> {
    return await this.get<IStage[]>("/api/venues/recommended", cancelToken);
  }

  async getCommandConfig(cancelToken?: CancelToken): Promise<CommandConfig> {
    const result = await this.get<CommandConfig>(
      "/api/commands/config",
      cancelToken
    );
    return result;
  }

  async sendCommand(
    command: Command,
    cancelToken?: CancelToken
  ): Promise<string> {
    const result = await this.post<string>(
      "/api/commands/send",
      command,
      cancelToken
    );
    return result;
  }

  async sendCommandV2(
    command: CommandV2,
    cancelToken?: CancelToken
  ): Promise<string> {
    const result = await this.post<string>(
      "/api/commands/v2/send",
      command,
      cancelToken
    );
    return result;
  }

  public setAccessToken(token: string | null) {
    if (token) {
      this.setHeader("Authorization", `Bearer ${token}`);
    } else {
      this.setHeader("Authorization", null);
    }
  }
  async generateAzureCommunicationServiceAccessToken(): Promise<AzureCommunicationToken> {
    return this.post<AzureCommunicationToken>(
      "/api/communications/generateCommunicationToken",
      null
    );
  }
  async refreshAzureCommunicationServiceAccessToken(
    identity: string
  ): Promise<AzureCommunicationToken> {
    return this.post<AzureCommunicationToken>(
      "/api/communications/refreshCommunicationToken",
      identity
    );
  }
  public createCancelTokenSource(): CancelTokenSource {
    return axios.CancelToken.source();
  }

  async getMeetingConfig(cancelToken?: CancelToken): Promise<EventsConfig> {
    const result = await this.get<EventsConfig>(
      "/api/meetings/config",
      cancelToken
    );
    return result;
  }

  async getEvent(
    eventId: string,
    sessionId?: string,
    cancelToken?: CancelToken
  ): Promise<Event> {
    return sessionId
      ? await this.get<Event>(
          `/api/meetings/${eventId}/${sessionId}`,
          cancelToken
        )
      : await this.get<Event>(`/api/meetings/${eventId}`, cancelToken);
  }
  async getEventWithRecordings(
    eventId: string,
    sessionId?: string,
    cancelToken?: CancelToken
  ): Promise<Event> {
    return await this.get<Event>(
      `/api/meetings/details/${eventId}/${sessionId}`,
      cancelToken
    );
  }
  async getMeetingByMsId(
    msId: string,
    cancelToken?: CancelToken
  ): Promise<Event> {
    return await this.get<Event>(`/api/meetings/byMsId/${msId}`, cancelToken);
  }

  async updateMeeting(
    meeting: Partial<Event>,
    cancelToken?: CancelToken
  ): Promise<Event> {
    return await this.patch(
      `/api/meetings/${meeting.id}`,
      meeting,
      cancelToken
    );
  }

  async getUsersCount(cancelToken?: CancelToken): Promise<number> {
    return await this.get<number>("/api/organization/users/count", cancelToken);
  }

  async getUsers(
    offset: number,
    count: number,
    cancelToken?: CancelToken
  ): Promise<Array<User>> {
    const result = await this.get<Array<User>>(
      "/api/organization/users?offset=" + offset + "&count=" + count,
      cancelToken
    );
    return result;
  }

  async createMeetingByMsId(
    request: CreateMeetingByMsIdRequest,
    cancelToken?: CancelToken
  ): Promise<Event> {
    return await this.post<Event>("/api/meetings/byMsId", request, cancelToken);
  }

  async updateEventByMsId(
    msId: string,
    request: UpdateEventByMsIdRequest,
    cancelToken?: CancelToken
  ): Promise<Event> {
    return await this.patch<Event>(
      `/api/meetings/byMsId/${msId}`,
      request,
      cancelToken
    );
  }

  async updateSpeakersAdditionalInfo(
    eventId: string,
    additionalInfo: IEventAdditionalInfo,
    cancelToken?: CancelToken
  ): Promise<void> {
    return await this.post(
      `/api/meetings/updatespeakerscallInfo/${eventId}`,
      additionalInfo,
      cancelToken
    );
  }
  async updateListenersAdditionalInfo(
    eventId: string,
    additionalInfo: IEventAdditionalInfo,
    cancelToken?: CancelToken
  ): Promise<void> {
    return await this.post(
      `/api/meetings/updatelistenerscallInfo/${eventId}`,
      additionalInfo,
      cancelToken
    );
  }
  async getIsOwnerByTeamsId(
    teamsId: string,
    cancelToken?: CancelToken
  ): Promise<GetIsOwnerByTeamsIdResponse> {
    return await this.get<GetIsOwnerByTeamsIdResponse>(
      `/api/meetings/byMsId/${teamsId}/getIsOwner`,
      cancelToken
    );
  }
  async getIsOwner(
    eventId: string,
    cancelToken?: CancelToken
  ): Promise<GetIsOwnerByTeamsIdResponse> {
    return await this.get<GetIsOwnerByTeamsIdResponse>(
      `/api/meetings/${eventId}/getIsOwner`,
      cancelToken
    );
  }

  async searchUsers(
    searchString: string,
    cancelToken?: CancelToken
  ): Promise<Array<User>> {
    const result = await this.get<Array<User>>(
      "/api/organization/searchUsers/" + searchString,
      cancelToken
    );
    return result;
  }

  async scheduleMeeting(
    request: ScheduleMeetingRequest,
    cancelToken?: CancelToken
  ): Promise<Event> {
    const result = await this.post<Event>(
      "/api/meetings/schedulemeeting",
      request,
      cancelToken
    );
    return result;
  }
  async scheduleEvent(
    request: ScheduleMeetingRequest,
    cancelToken?: CancelToken
  ): Promise<Event> {
    const result = await this.post<Event>(
      "/api/meetings/scheduleevent",
      request,
      cancelToken
    );
    return result;
  }
  async startMeetingNow(
    request: StartMeetingNowRequest,
    cancelToken?: CancelToken
  ): Promise<Event> {
    const result = await this.post<Event>(
      "/api/meetings/startMeetingNow",
      request,
      cancelToken
    );
    return result;
  }
  async startEventNow(
    request: StartMeetingNowRequest,
    cancelToken?: CancelToken
  ): Promise<Event> {
    const result = await this.post<Event>(
      "/api/meetings/startEventNow",
      request,
      cancelToken
    );
    return result;
  }
  async joinMeetingByJoinUrl(
    request: JoinMeetingByJoinUrlRequest,
    cancelToken?: CancelToken
  ): Promise<Event> {
    const result = await this.post<Event>(
      "/api/meetings/joinMeetingByJoinUrl",
      request,
      cancelToken
    );
    return result;
  }
  async joinMeetingByMeetingMsId(
    request: JoinMeetingByTeamsIdRequest,
    cancelToken?: CancelToken
  ): Promise<Event> {
    const result = await this.post<Event>(
      "/api/meetings/joinMeetingByMsId",
      request,
      cancelToken
    );
    return result;
  }
  async getUserMeetings(
    offset: number,
    count: number,
    type: EventType | null,
    status: EventSessionStatus | null,
    cancelToken?: CancelToken
  ): Promise<PaginatedList<Event>> {
    let url = "/api/users/me/meetings?offset=" + offset + "&count=" + count;
    if (type) {
      url += "&type=" + type;
    }
    if (status) {
      url += "&status=" + status;
    }
    const result = await this.get<PaginatedList<Event>>(url, cancelToken);
    return result;
  }

  async getLogos(cancelToken?: CancelToken): Promise<Logo[]> {
    return await this.get<Logo[]>(`api/users/me/logos`, cancelToken);
  }

  async createLogo(uploadId: string, cancelToken?: CancelToken): Promise<Logo> {
    return await this.post<Logo>(
      `api/users/me/logos`,
      {
        uploadId: uploadId,
      },
      cancelToken
    );
  }
  async removeLogo(logoId: string, cancelToken?: CancelToken): Promise<Logo> {
    return await this.delete<Logo>(
      `/api/users/me/logos/${logoId}`,
      cancelToken
    );
  }

  async getUserInfo(cancelToken?: CancelToken): Promise<User> {
    const result = await this.get<User>("api/users/me/info", cancelToken);
    return result;
  }
  async getUserInfoTeams(
    teamsAccessToken: string,
    cancelToken?: CancelToken
  ): Promise<User> {
    const result = await this.get<User>(
      `api/users/me/info/teams?teamsAccessToken=${teamsAccessToken}`,
      cancelToken
    );
    return result;
  }
  async getParticipants(
    eventId: string,
    cancelToken?: CancelToken
  ): Promise<ParticipantsCollection> {
    const result = await this.get<ParticipantsCollection>(
      "api/call/" + eventId + "/currentParticipants",
      cancelToken
    );
    return result;
  }

  async setActiveSpeaker(
    eventId: string,
    speakerId: string | null,
    cancelToken?: CancelToken
  ): Promise<void> {
    return await this.post<void>(
      "api/call/" + eventId + "/setActiveSpeaker",
      {
        id: speakerId,
      },
      cancelToken
    );
  }

  async setSpeechRecognitionState(
    eventId: string,
    isEnabled: boolean,
    cancelToken?: CancelToken
  ): Promise<void> {
    return await this.post<void>(
      `api/call/${eventId}/setSpeechRecognitionState?isEnabled=${isEnabled}`,
      null,
      cancelToken
    );
  }

  async setFormFulfillmentState(
    eventId: string,
    isEnabled: boolean,
    cancelToken?: CancelToken
  ): Promise<void> {
    return await this.post<void>(
      `api/call/${eventId}/setFormFulfillmentState?isEnabled=${isEnabled}`,
      null,
      cancelToken
    );
  }

  async setSegmentationState(
    eventId: string,
    isEnabled: boolean,
    cancelToken?: CancelToken
  ): Promise<void> {
    return await this.post<void>(
      `api/call/${eventId}/setSegmentationState?isEnabled=${isEnabled}`,
      null,
      cancelToken
    );
  }

  async setGazeRedirectionState(
    eventId: string,
    isEnabled: boolean,
    cancelToken?: CancelToken
  ): Promise<void> {
    return await this.post<void>(
      `api/call/${eventId}/setGazeRedirectionState?isEnabled=${isEnabled}`,
      null,
      cancelToken
    );
  }

  async setListenersAudioState(
    eventId: string,
    isMuted: boolean,
    cancelToken?: CancelToken
  ): Promise<void> {
    return await this.post<void>(
      `api/call/${eventId}/setListenersAudioState?isMuted=${isMuted}`,
      null,
      cancelToken
    );
  }

  async setTargetLanguage(
    eventId: string,
    isListenersCall: boolean,
    targetLanguage: string | null,
    cancelToken?: CancelToken
  ): Promise<void> {
    let url = `api/call/${eventId}/setTargetLanguage?isListenersCall=${isListenersCall}`;
    if (targetLanguage) {
      url += `&targetLanguage=${targetLanguage}`;
    }
    return await this.post<void>(url, null, cancelToken);
  }

  async getSupportedLanguages(
    eventId: string,
    cancelToken?: CancelToken
  ): Promise<Language[]> {
    return await this.get<Language[]>(
      `api/call/${eventId}/getSupportedLanguages`,
      cancelToken
    );
  }
  async attachSlates(
    eventId: string,
    ids: Array<string>,
    cancelToken?: CancelToken
  ): Promise<Event> {
    return await this.patch<Event>(
      `api/meetings/${eventId}/slate`,
      {
        slates: ids,
      },
      cancelToken
    );
  }
  async setLogo(
    eventId: string,
    logoId: string,
    cancelToken?: CancelToken
  ): Promise<Event> {
    return await this.patch<Event>(
      `api/meetings/${eventId}/logo`,
      {
        logoId: logoId,
      },
      cancelToken
    );
  }

  async endMeeting(eventId: string, cancelToken?: CancelToken): Promise<void> {
    return await this.post<void>(`api/call/${eventId}/end`, null, cancelToken);
  }
  async getOutputUrl(
    eventId: string,
    cancelToken?: CancelToken
  ): Promise<Event> {
    return await this.get<Event>(`api/player/${eventId}`, cancelToken);
  }

  async prepareUpload(
    request: PrepareUploadRequest,
    cancelToken?: CancelToken
  ): Promise<PrepareUploadResponse> {
    return await this.post<PrepareUploadResponse>(
      `api/uploads/prepare`,
      request,
      cancelToken
    );
  }

  async getSurveys(
    organizationId: string,
    cancelToken?: CancelToken
  ): Promise<ISurvey[]> {
    return await this.get<ISurvey[]>(
      `api/survey/getsurveys/${organizationId}`,
      cancelToken
    );
  }

  async getSurveyById(
    request: SurveyRequest,
    cancelToken?: CancelToken
  ): Promise<ISurvey> {
    return await this.get<ISurvey>(
      `api/survey/getsurveybyid/${request.organizationId}/${request.surveyId}`,
      cancelToken
    );
  }

  async saveSurveyResponse(
    saveSurveyRequest: SaveSurveyRequest,
    cancelToken?: CancelToken
  ): Promise<boolean> {
    return await this.post<boolean>(
      `api/survey/savesurveyresponse`,
      saveSurveyRequest,
      cancelToken
    );
  }

  async getQuestionsAnalytics(
    query: string,
    cancelToken?: CancelToken
  ): Promise<IQuestions> {
    return await this.get<IQuestions>(
      `api/eventquestion/geteventquestions/${query}`,
      cancelToken
    );
  }

  async getQuestionsCount(
    eventId: string,
    cancelToken?: CancelToken
  ): Promise<number> {
    return await this.get<number>(
      `api/eventquestion/count/${eventId}`,
      cancelToken
    );
  }

  async downloadCsvFile(
    eventId: string,
    cancelToken?: CancelToken
  ): Promise<File> {
    return await this.getBlob(
      `api/eventquestion/downloadCsvFile/${eventId}`,
      cancelToken
    );
  }
}

const apiClient = new ApiClient();

const iMeetingClient = apiClient as IMeetingClient;
const iCommandClient = apiClient as ICommandClient;
const iCancellableClient = apiClient as ICancellableClient;
const iAuthenticationClient = apiClient as IAuthenticationClient;
const iOrganizationClient = apiClient as IOrganizationClient;
const iUserClient = apiClient as IUserClient;
const eventEmitter = apiClient as EventEmitter;
const iStageClient = apiClient as IStageClient;
const iPlayerClient = apiClient as IPlayerClient;
const iAzureCommunicationServicesClient =
  apiClient as IAzureCommunicationServicesClient;
const ISendMessageClient = apiClient as ISendMessage;
const iUtilitiesClient = apiClient as IUtilitiesClient;
const iUploadsClient = apiClient as IUploadsClient;
const iAzureApplicationClient = apiClient as IAzureApplicationClient;
const iSurveyClient = apiClient as ISurveyClient;

export {
  apiClient,
  iCancellableClient,
  iMeetingClient,
  iCommandClient,
  iAuthenticationClient,
  iOrganizationClient,
  iUserClient,
  iStageClient,
  iPlayerClient,
  iAzureCommunicationServicesClient,
  ISendMessageClient,
  iUtilitiesClient,
  iUploadsClient,
  eventEmitter,
  iAzureApplicationClient,
  iSurveyClient,
};
