import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Event from "../api/payloads/Event";
import IMeetingDetailsStore from "../slices/props/IMeetingDetailsStore";
import { iCommandClient, iMeetingClient, apiClient } from "../api/ApiClient";
import Command from "../api/payloads/Command";
import { EventSessionStatus } from "../api/payloads/EventSessionStatus";
import { meetingScheduled } from "../features/main/meeting/schedule/scheduleMeetingSlice";
import { meetingStarted } from "../features/main/meeting/startNow/StartNowSlice";
import ParticipantsCollection from "../api/payloads/ParticipantsCollection";
import { preloadMeeting as remotePreloadMeeting } from "../features/main/user/meetings/userMeetingsSlice";
import { ThunkActionState } from "../helpers/ThunkActionState";
import { meetingChanged } from "../slices/CallWebSocketSlice";
import EventChangedPayload from "./props/EventChangedPayload";
import MetaverseApiError from "api/payloads/MetaverseApiError";
import { ErrorCode } from "api/payloads/ErrorCode";
import { eventLogoUpdated } from "slices/MeetingSlice";
import CommandV2 from "api/payloads/CommandV2";
import { GenerateLinksRequest } from "api/payloads/GenerateLinksRequest";
import GenerateLinksResponse from "api/payloads/GenerateLinksResponse";
import Language from "api/payloads/Language";

const initialState: IMeetingDetailsStore = {
    participants: {},
    supportedLanguages: [],
    loadingMeetingState: ThunkActionState.idle,
    updatingMeetingState: ThunkActionState.idle,
    sendingCommandState: ThunkActionState.idle,
    settingActiveSpeakerState: ThunkActionState.idle,
    loadingSpeakersState: ThunkActionState.idle,
    endingMeetingState: ThunkActionState.idle,
    loadingMeetingByMsIdState: ThunkActionState.idle,
};

export const loadMeetingByMsIdAsync = createAsyncThunk("meetingDetails/loadMeetingByMsId", async (msId: string, { dispatch, rejectWithValue }) => {
    try {
        const meeting = await iMeetingClient.getMeetingByMsId(msId);
        dispatch(slice.actions.meetingLoaded(meeting));
        return meeting;
    } catch (error: any) {
        if (error instanceof MetaverseApiError) {
            if (error.errorCode === ErrorCode.EventNotFound) {
                dispatch(slice.actions.meetingLoaded(null));
            }
        }
        return rejectWithValue(error);
    }
});

export const getSupportedLanguagesAsync = createAsyncThunk("meetingDetails/getSupportedLanguages", async (eventId: string, { dispatch, rejectWithValue }) => {
    try {
        const supportedLanguages = await iMeetingClient.getSupportedLanguages(eventId);
        dispatch(slice.actions.supportedLanguagesLoaded(supportedLanguages));
        return supportedLanguages;
    } catch (error: any) {
        return rejectWithValue(error);
    }
});

export const setTargetLanguageAsync = createAsyncThunk("meetingsDetails/setTargetLanguage", async (request: {
    eventId: string,
    isListenersCall: boolean,
    targetLanguage: string | null,
}) => {
    await iMeetingClient.setTargetLanguage(request.eventId, request.isListenersCall, request.targetLanguage);
});

export const getIsOwnerByTeamsIdAsync = createAsyncThunk("meetingDetails/getIsOwnerByTeamsId", async (teamsId: string, { dispatch }) => {
    const response = await iMeetingClient.getIsOwnerByTeamsId(teamsId);
    dispatch(slice.actions.setIsOwner(response.isOwner))
});

export const getIsOwnerAsync = createAsyncThunk("meetingDetails/getIsOwner", async (eventId: string, { dispatch }) => {
    const response = await iMeetingClient.getIsOwner(eventId);
    dispatch(slice.actions.setIsOwner(response.isOwner));
});

export const loadMeetingAsync = createAsyncThunk("meetingDetails/loadMeeting", async ({ eventId, sessionId }: { eventId: string, sessionId: string | undefined }, { dispatch, rejectWithValue }) => {
    try {
        const meeting = await iMeetingClient.getEvent(eventId, sessionId);
        dispatch(slice.actions.meetingLoaded(meeting));
        return;
    } catch (error: any) {
        dispatch(slice.actions.meetingLoaded(null));
        return rejectWithValue(error);
    }
});
export const updateMeetingAsync = createAsyncThunk("meetingDetails/updateMeeting", async (meeting: Partial<Event>, { dispatch }) => {
    const updatedMeeting = await iMeetingClient.updateMeeting(meeting);
    dispatch(slice.actions.meetingUpdated(updatedMeeting));
});
export const prepareRecordingLinks = createAsyncThunk("meetings/generateLinks", async (payload: {
    storageUrl: string,
    organizationId: string,
    recources: Array<string>
}, { dispatch, rejectWithValue }) => {
    try {
        const { recources, organizationId, storageUrl } = payload;
        console.log(payload);

        const generateLinksRequest: GenerateLinksRequest = {
            uploadIds: recources
        };
        let url = `${storageUrl}/files/bulkdownload?app_id=1&group=${organizationId}`;
        const result = await apiClient.post<GenerateLinksResponse>(url, generateLinksRequest);
        console.log(result);
        dispatch(slice.actions.recordingsLoaded(result));
        return result;
    }
    catch (error) {
        console.log(error);
        return rejectWithValue(error);
    }
})
export const sendCommandAsync = createAsyncThunk('meetingDetails/sendCommand', async (command: Command) => {
    await iCommandClient.sendCommand(command);
});

export const sendCommandV2Async = createAsyncThunk("meetingDetails/sendCommandV2", async (command: CommandV2) => {
    await iCommandClient.sendCommandV2(command);
});

export const loadCurrentParticipantsAsync = createAsyncThunk("meetingDetails/loadCurrentParticipants", async (eventId: string, { dispatch }) => {
    const participants = await iMeetingClient.getParticipants(eventId);
    dispatch(slice.actions.participantsLoaded(participants));
    return participants;
});

export const setActiveSpeakerAsync = createAsyncThunk("meetingDetails/setActiveSpeaker", async (
    { eventId, speakerId }: { eventId: string, speakerId: string | null }) => {
    await iMeetingClient.setActiveSpeaker(eventId, speakerId);
}
);

export const endMeetingAsync = createAsyncThunk("meetingDetails/endMeeting", async (eventId: string) => {
    await iMeetingClient.endMeeting(eventId);
});

export const setSpeechRecognitionStateAsync = createAsyncThunk("meetingDetails/setSpeechRecognitionState", async ({ eventId, isEnabled }: { eventId: string, isEnabled: boolean }) => {
    await iMeetingClient.setSpeechRecognitionState(eventId, isEnabled);
});

export const setFormFulfillmentStateAsync = createAsyncThunk("meetingDetails/setFormFulfillmentState", async ({ eventId, isEnabled } : { eventId: string, isEnabled: boolean }) => {
    await iMeetingClient.setFormFulfillmentState(eventId, isEnabled);
});

export const setSegmentationStateAsync = createAsyncThunk("meetingDetails/setSegmentationState", async ({ eventId, isEnabled }: { eventId: string, isEnabled: boolean }, { dispatch }) => {
    await iMeetingClient.setSegmentationState(eventId, isEnabled);
});

export const setGazeRedirectionStateAsync = createAsyncThunk("meetingDetails/setGazeRedirectionState", async ({ eventId, isEnabled }: { eventId: string, isEnabled: boolean }, { dispatch }) => {
    await iMeetingClient.setGazeRedirectionState(eventId, isEnabled);
});

export const setListenersAudioStateAsync = createAsyncThunk("meetingDetails/setListenersAudioState", async ({ eventId, isMuted }: { eventId: string, isMuted: boolean }, { dispatch }) => {
    await iMeetingClient.setListenersAudioState(eventId, isMuted);
});

const slice = createSlice({
    name: "meetingDetails",
    initialState,
    reducers: {
        meetingLoaded: (state: IMeetingDetailsStore, action: PayloadAction<Event | null>) => {
            state.meeting = action.payload;
        },
        setIsOwner: (state: IMeetingDetailsStore, action: PayloadAction<boolean>) => {
            state.isOwner = action.payload;
        },
        reset: () => initialState,
        meetingUpdated: (state: IMeetingDetailsStore, action: PayloadAction<Event>) => {
            state.meeting = action.payload;
        },
        meetingStatusUpdated: (state: IMeetingDetailsStore, action: PayloadAction<EventSessionStatus>) => {
            if (state.meeting) {
                let session = state.meeting.sessions.find(s => s.isCurrent);
                if (session) {
                    session.status = action.payload;
                }
            }
        },
        recordingsLoaded: (state: IMeetingDetailsStore, action: PayloadAction<GenerateLinksResponse>) => {
            if (state.meeting) {
                console.log(state.meeting)
                let session = state.meeting.sessions.find(s => s.isCurrent);
                console.log(session)
                const downloads = action.payload.downloads;
                console.log(downloads)
                if (session) {
                    session.recordings.forEach(r => {
                        const download = downloads.find(d => d.resourceId === r.uploadId);
                        if (download) {
                            r.url = download.url;
                        }
                    });

                    const transcription = session.transcription;
                    if (transcription) {
                        const download = downloads.find(d => d.resourceId === transcription.uploadId);
                        if (download) {
                            transcription.url = download.url;
                        }
                    }
                }
            }
        },
        participantsLoaded: (state: IMeetingDetailsStore, action: PayloadAction<ParticipantsCollection>) => {
            state.participants = action.payload;
        },
        preloadMeeting: (state: IMeetingDetailsStore, action: PayloadAction<Event>) => {
            state.meeting = action.payload;
        },
        supportedLanguagesLoaded: (state: IMeetingDetailsStore, action: PayloadAction<Language[]>) => {
            state.supportedLanguages = action.payload;
        },
    },
    extraReducers: {
        [meetingChanged.toString()]: (state: IMeetingDetailsStore, action: PayloadAction<EventChangedPayload>) => {
            const { payload } = action;

            if (payload.eventId === state.meeting?.id) {
                let session = state.meeting.sessions.find(s => s.isCurrent);
                if (session) {
                    session.status = payload.status;
                    session.venueState = payload.venueState;
                    session.serverState = payload.serverState;
                    session.error = payload.error;
                } else {
                    session = {
                        id: payload.sessionId,
                        isCurrent: true,
                        status: payload.status,
                        venueState: payload.venueState,
                        serverState: payload.serverState,
                        error: payload.error,
                        features: payload.features,
                        recordings: [],
                    };

                    state.meeting.sessions.push(session);
                }
            }
        },

        [eventLogoUpdated.toString()]: (state: IMeetingDetailsStore, action: PayloadAction<string>) => {
            if (state.meeting) {
                state.meeting.logoName = action.payload;
            }
        },
        [loadMeetingAsync.pending.toString()]: (state: IMeetingDetailsStore) => {
            state.loadingMeetingState = ThunkActionState.pending;
        },
        [loadMeetingAsync.fulfilled.toString()]: (state: IMeetingDetailsStore) => {
            state.loadingMeetingState = ThunkActionState.fulfilled;
        },
        [loadMeetingAsync.rejected.toString()]: (state: IMeetingDetailsStore) => {
            state.loadingMeetingState = ThunkActionState.rejected;
        },

        [updateMeetingAsync.pending.toString()]: (state: IMeetingDetailsStore) => {
            state.updatingMeetingState = ThunkActionState.pending;
        },
        [updateMeetingAsync.fulfilled.toString()]: (state: IMeetingDetailsStore) => {
            state.updatingMeetingState = ThunkActionState.fulfilled;
        },
        [updateMeetingAsync.rejected.toString()]: (state: IMeetingDetailsStore) => {
            state.updatingMeetingState = ThunkActionState.rejected;
        },

        [sendCommandAsync.pending.toString()]: (state: IMeetingDetailsStore) => {
            state.sendingCommandState = ThunkActionState.pending;
        },
        [sendCommandAsync.fulfilled.toString()]: (state: IMeetingDetailsStore) => {
            state.sendingCommandState = ThunkActionState.fulfilled;
        },
        [sendCommandAsync.rejected.toString()]: (state: IMeetingDetailsStore) => {
            state.sendingCommandState = ThunkActionState.rejected;
        },

        [setActiveSpeakerAsync.pending.toString()]: (state: IMeetingDetailsStore) => {
            state.settingActiveSpeakerState = ThunkActionState.pending;
        },
        [setActiveSpeakerAsync.fulfilled.toString()]: (state: IMeetingDetailsStore) => {
            state.settingActiveSpeakerState = ThunkActionState.fulfilled;
        },
        [setActiveSpeakerAsync.rejected.toString()]: (state: IMeetingDetailsStore) => {
            state.settingActiveSpeakerState = ThunkActionState.rejected;
        },

        [loadCurrentParticipantsAsync.pending.toString()]: (state: IMeetingDetailsStore) => {
            state.loadingSpeakersState = ThunkActionState.pending;
        },
        [loadCurrentParticipantsAsync.fulfilled.toString()]: (state: IMeetingDetailsStore) => {
            state.loadingSpeakersState = ThunkActionState.fulfilled;
        },
        [loadCurrentParticipantsAsync.rejected.toString()]: (state: IMeetingDetailsStore) => {
            state.loadingSpeakersState = ThunkActionState.rejected;
        },

        [endMeetingAsync.pending.toString()]: (state: IMeetingDetailsStore) => {
            state.endingMeetingState = ThunkActionState.pending;
        },
        [endMeetingAsync.fulfilled.toString()]: (state: IMeetingDetailsStore) => {
            state.endingMeetingState = ThunkActionState.fulfilled;
        },
        [endMeetingAsync.rejected.toString()]: (state: IMeetingDetailsStore) => {
            state.endingMeetingState = ThunkActionState.rejected;
        },

        [loadMeetingByMsIdAsync.pending.toString()]: (state: IMeetingDetailsStore) => {
            state.loadingMeetingByMsIdState = ThunkActionState.pending;
        },
        [loadMeetingByMsIdAsync.fulfilled.toString()]: (state: IMeetingDetailsStore) => {
            state.loadingMeetingByMsIdState = ThunkActionState.fulfilled;
        },
        [loadMeetingByMsIdAsync.rejected.toString()]: (state: IMeetingDetailsStore) => {
            state.loadingMeetingByMsIdState = ThunkActionState.rejected;
        },

        [meetingScheduled.toString()]: (state: IMeetingDetailsStore, action: PayloadAction<Event>) => {
            state.meeting = action.payload;
        },
        [meetingStarted.toString()]: (state: IMeetingDetailsStore, action: PayloadAction<Event>) => {
            state.meeting = action.payload;
        },
        [remotePreloadMeeting.toString()]: (state: IMeetingDetailsStore, action: PayloadAction<Event>) => {
            state.meeting = action.payload;
        },
    }
});

export const {
    reset,
    preloadMeeting,
    meetingLoaded,
    meetingUpdated,
} = slice.actions;

export default slice.reducer;